<template>
  <page-skeleton theme="light">
    <section-half-image
        hero
        arrow
        scroll-to="ampoules-arrow"
        image-path="https://ik.imagekit.io/isotronic/products/ampoules/ampoules_O8OsJpriGyI_NE05Bab8GH.png?updatedAt=1635265132910"
        img-background="reverse-gradient"
        :alt="$t('products.ampoules.hero.alt')"
        small-photo
    >
      <template #header>
        {{ $t('products.ampoules.hero.header') }}
      </template>
      <template #content>
        {{ $t('products.ampoules.hero.text') }}
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/ampoules/ampoules_diagonal_DeXpnBBK6YJ_1Jx0ZAPO9.jpg?updatedAt=1635265229612"
        background="light"
        id="ampoules-arrow"
        photo-right-align
        :alt="$t('products.ampoules.section1.alt')"
        small-photo
        round-radius
    >
      <template #header>
        {{ $t('products.ampoules.section1.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.ampoules.section1.text') }}
        </p>
        <p>
          {{ $t('products.ampoules.section1.text2') }}
        </p>
      </template>
    </section-half-image>
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/ampoules/airline_vial_bottom_dor_ZufTx4S_w5KyN9tpm.jpg?updatedAt=1635265267305"
        :alt="$t('products.ampoules.section2.alt')"
    >
      <template #header>
        {{ $t('products.ampoules.section2.header') }}
      </template>
      <template #content>
        <div>
          {{ $t('products.ampoules.section2.text') }}
        </div>
        <ul class="mt-3">
          <li>
            <i18n path="products.ampoules.section2.feature1.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.ampoules.section2.feature1.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.ampoules.section2.feature2.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.ampoules.section2.feature2.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.ampoules.section2.feature3.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.ampoules.section2.feature3.style') }}
                  </span>
              </template>
            </i18n>
          </li>
          <li class="mt-1">
            <i18n path="products.ampoules.section2.feature4.text" tag="div">
              <template v-slot:style>
                  <span class="i-secondary i-font-text-bold">
                    {{ $t('products.ampoules.section2.feature4.style') }}
                  </span>
              </template>
            </i18n>
          </li>
        </ul>
        <div class="mt-2">
          {{$t('products.ampoules.section2.text2')}}
        </div>
      </template>
    </section-half-image>
    <full-width-image
        image-path="https://ik.imagekit.io/isotronic/products/ampoules/rolled_tubes_4omDjcuFx_mDfg-lmlT.jpg?updatedAt=1635265300904"
        :alt="$t('products.ampoules.fullImages.alt1')"
    />
    <section-half-image
        image-path="https://ik.imagekit.io/isotronic/products/ampoules/ampoules_on_table_oeoJ5rPy31q_YVtlsBGWf_.jpg?updatedAt=1635265338077"
        photo-right-align
        button-link="Contact"
        background="light"
        :alt="$t('products.ampoules.section3.alt')"
    >
      <template #header>
        {{ $t('products.ampoules.section3.header') }}
      </template>
      <template #content>
        <p>
          {{ $t('products.ampoules.section3.text') }}
        </p>
        <p>
          {{ $t('products.ampoules.section3.text2') }}
        </p>
      </template>
      <template #callout>
        {{$t('products.ampoules.section3.callout')}}
      </template>
    </section-half-image>
  </page-skeleton>
</template>

<script>
import PageSkeleton from "@/components/utility/PageSkeleton";
import SectionHalfImage from "@/components/utility/SectionHalfImage";
import FullWidthImage from "@/components/utility/FullWidthImage";

export default {
  components: {
    PageSkeleton,
    SectionHalfImage,
    FullWidthImage
  },
  metaInfo() {
    return {
      title: this.$t('products.ampoules.meta.title'),
      meta: [
        {vmid: 'description', name: 'description', content: this.$t('products.ampoules.meta.description')}
      ]
    }
  },
}
</script>
